<template>
  <div>
    <a-card title="线下销售单收款">
      <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost v-print="'#printContent'"> <a-icon type="printer" />打印</a-button>
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售单号">
              {{ info.sales_order }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ info.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="处理日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="其他费用">
              {{ info.other_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px;">产品信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columns"
            :data-source="info.sales_goods_items"
            :pagination="false" />
        </a-spin>
      </section>
      <a-divider orientation="left" style="margin-top: 30px;">收款信息:已收款金额：{{ info.collection_amount }}      欠款金额：{{ info.arrears_amount }}</a-divider>
      <div>
          <a-row :gutter="16">
            <a-space>
              <a-button type="primary" @click="handelAddAcount">添加收款账户</a-button>
            </a-space>
          </a-row>
          <div style="margin-top: 16px;">
              <a-table rowKey="id" size="middle" :columns="columnsAccount" :data-source="sales_account_items"
                :pagination="false">
                <div slot="account" slot-scope="value, item, index">
                  <a-select :disabled = "item.collection_amount > 0" v-if="!item.isTotal" v-model="item.account" style="width: 100%" @change="(value) => changeAccount(value, item, index)">
                    <a-select-option v-for="Account in accountsItems" :key="Account.id"
                      :value="Account.id">
                      {{ Account.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div slot="collection_amount" slot-scope="value, item, index">
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number v-else style="width: 100%"
                    v-model="item.collection_amount"
                    :min="0"
                    :disabled = "true"
                    :precision="2"></a-input-number>
                </div>
                <div slot="receipt_amount" slot-scope="value, item, index">
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number v-else style="width: 100%"
                    v-model="item.receipt_amount"
                    :min="0"
                    :precision="2"></a-input-number>
                </div>
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group v-if="(!item.isTotal && (item.collection_amount < 0.001))" size="small">
                    <a-button type="danger" @click="removeAccount(item)">移除</a-button>
                  </a-button-group>
                </div>
              </a-table>
          </div>
        </div>


      <div style="margin-top: 32px;">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>

    </a-card>
  </div>
</template>

<script>
  import { saleOrderDetail, saleOrderReceipt } from '@/api/sale'
  import { clientArrearsOption, userOption, accountsOption } from '@/api/option'
  import JsBarcode from 'jsbarcode'
  import NP from 'number-precision'

export default {
    data() {
      return {
        warehouseItems: [],
        handlerItems: [],
        clientArrearsItems: [],
        accountsItems: [],
        materialsSelectModalVisible: false,
        model: {},
        form: {},
        loading: false,
        materialLoading: false,
        info: {},
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
            key: 'goods_name',
            width: 150,
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
            key: 'goods_number',
            width: 150,
          },
          {
            title: '单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
          },
          {
            title: '销售数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
            width: 120,
          },
          {
            title: '销售单价(元)',
            dataIndex: 'sales_price',
            key: 'sales_price',
            width: 120,
          },
          {
            title: '金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 200,
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.sales_quantity, item.sales_price);
              return item.id ? NP.round(value, 2) : ''
            },
          }
        ],
        columnsAccount: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '收款账户',
            dataIndex: 'account',
            key: 'account',
            width: 200,
            scopedSlots: { customRender: 'account' },
          },
          {
            title: '已收款金额',
            dataIndex: 'collection_amount',
            key: 'collection_amount',
            width: 200,
            scopedSlots: { customRender: 'collection_amount' },
          },
          {
            title: '收款金额',
            dataIndex: 'receipt_amount',
            key: 'receipt_amount',
            width: 200,
            scopedSlots: { customRender: 'receipt_amount' },
          },

          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' },
          },
        ],
        sales_account_items:[],
      }
    },

    computed: {


    },

    methods: {
      getJsBarcode(number) {
        JsBarcode("#barcode", number, {
          lineColor: '#000',
          width: 2,
          height: 40,
          displayValue: true
        });
      },
      handleSelectChange () {

      },
      initData() {
        this.loading = true;
        saleOrderDetail({ id: this.$route.query.id }).then(data => {
          this.info = data;
          for (let i in data.sales_account_items){
              //data.sales_account_items[i][""] = 0;
              this.$set(data.sales_account_items[i], 'receipt_amount', 0)
              this.sales_account_items.push(data.sales_account_items[i])
          }


          //this.sales_account_items = data.sales_account_items
          //console.log("xxxx:",this.sales_account_items)
          //this.sales_account_items = [
          //  ...this.sales_account_items,
          //  {
          //    id: '-1',
          //    isTotal: true,
          //    collection_amount: this.info.collection_amount,
          //  },
          //];
          /*this.sales_goods_items = [
            ...this.sales_goods_items,
            {
              id: '-1',
              isTotal: true,
              sales_quantity: this.info.total_quantity,
              totalAmount: this.info.total_amount,
            },
          ];*/
          this.getJsBarcode(data.number)
        }).finally(() => {
          this.loading = false;
        });

        clientArrearsOption({ page_size: 999999, is_active: true }).then(data => {
          this.clientArrearsItems = data.results;
        });
        accountsOption({ page_size: 999999, is_active: true }).then(data => {
          this.accountsItems = data.results;
        });

      },

      create() {
        let params = {
            sales_order: this.info.id,
            sale_account_items: this.sales_account_items
        }
        this.loading = true;
        saleOrderReceipt(params).then(data => {
          this.$message.success("收款成功");
        }).finally(() => {
          this.loading = false;
        });

        clientArrearsOption({ page_size: 999999, is_active: true }).then(data => {
          this.clientArrearsItems = data.results;
        });
        accountsOption({ page_size: 999999, is_active: true }).then(data => {
          this.accountsItems = data.results;
        });

      },


      handelAddAcount() {
        this.sales_account_items.push({
          id: this.sales_account_items.length + 1,
          account: '',
          collection_amount: 0,
          receipt_amount: 0
        });
        //console.log(this.sales_account_items)
      },
      removeAccount(item) {
        this.sale_account_items = this.$functions.removeItem(this.sales_account_items, item);
      },
      changeAccount(value, item, idx) {
        let count = this.sales_account_items.filter((_item) => {
          return _item.account == value;
        })
        if (count.length > 1) {
          this.$message.warn('已添加过该结算账户!');
          this.sales_account_items[idx].account = '';
        }
      },


    },
    mounted() {
      this.initData();

    },
  }
</script>
<style>
</style>
